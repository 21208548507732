.account-bg {
	height: 8rem;
}

.account-sidenav.navbar {
	background-color: #fff;
	border-radius: .5rem;
	padding: 0;

	.navbar-collapse {
		margin: {
			right: 0;
			left: 0;
		};
	}

	.navbar-nav {
		padding: 1.5rem;
		width: 100%;

		.nav-item {
			margin-bottom: .125rem;
			padding: .25rem 0;

			&.active {
				.nav-link {
					color: #2f3542;

					&:before {
						display: block;
						position: absolute;
						content: "";
						left: -1.5rem;
						bottom: 0;
						top: 0;
						border-right: 3px solid $primary;
					}
				}
			}
		}

		.nav-link {
			display: flex;
			align-items: center;
			background-color: transparent;
			border-radius: .5rem;
			color: $body-color;
			font-weight: 500;
			padding: 0;
			position: relative;


			i {
				opacity: .7;
			}

			&:hover {
				color: #2f3542;

				i {
					opacity: 1;
				}
			}
		}
	}

	.navbar-header {
		color: #a4b0be;
		//font-size: .6875rem;
		//font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: .2rem;
		padding: 0.3rem 0.2rem;
		text-transform: uppercase;
	}
}