@import "variables";
@import "mixins";
@import "utilities";
// Override Bootstrap Variables
//@import "boostrap-variables";
// KAASoft Fonts
//@import "fonts/variables";
// @import "./fonts/graviton";

//@import "reboot";
@import "./src/admin/scss/fonts/graviton";

@import "reboot";
/* -------------------------------------------------------------------------- */
/*                                   Vendors                                  */
/* -------------------------------------------------------------------------- */
@import "~bootstrap/scss/bootstrap";
@import "vendors/lazy-image";
//@import 'vendors/simplebar';

@import "vendors/custom-scrollbar";


@import "vendors/swiper";

@import "~placeholder-loading/src/scss/placeholder-loading";

//@import "~noty/src/noty.scss";
//@import "vendors/noty";
@import "vendors/notyf";

@import "vendors/read-more";
@import "vendors/eb-reader";
/* -------------------------------------------------------------------------- */
/*                                    Utils                                   */
/* -------------------------------------------------------------------------- */
//// Utils
@import "utils/margin";
//@import "utils/padding";
//@import "utils/width";
@import "utils/height";
@import "utils/border";
//@import "utils/opacity";
@import "utils/background";
//@import "utils/z-index";
//@import "utils/position";
@import "utils/typography";
//@import "utils/other";

/********************************************************************************
 *	COMPONENTS
 ********************************************************************************/
@import "components/nav";
@import "components/navbar";
@import "components/suggestion-search";
@import "components/button";
@import "components/avatar";
//@import "components/dropdown";
@import "components/dropdownv2";
@import "components/divider";
@import "components/input-group";
@import "components/card";
@import "components/list";
@import "components/tooltips";
@import "components/pagination";
@import "components/breadcrumb";
@import "components/widgets";
@import "components/custom-scrollbar";
@import "components/offcanvas";
@import "components/loading";
@import "components/form";
@import "components/modal";
@import "components/dialog";

/* -------------------------------------------------------------------------- */
/*                              Layout Components                             */
/* -------------------------------------------------------------------------- */
@import "layout/login";
@import "layout/header";
@import "layout/footer";
@import "layout/slider";
@import "layout/index";
//@import "layout/hero-search";
@import "layout/book-list";
@import "layout/filter";
@import "layout/book";
@import "layout/blog";
@import "layout/subscriptions";
@import "layout/user-area";

