.auth-code-wrapper {
	display: flex;
	justify-content: space-between;
	margin: .5rem 0 2rem 0;

	input {
		width: 3rem;
		height: 3rem;
		border-radius: .25rem;
		text-align: center;
		outline: 2px solid transparent;
		outline-offset: 2px;
		color: transparent;
		text-shadow: 0 0 0 gray;

		&:focus {
			background: $ghost-white-v2;
			border-color: $light-steel-blue;
		}
	}
}

//.auth-code-text {
//	text-align: center;
//	color: #a7a8cd;
//	margin-bottom: 2rem;
//}

.resend-code-btn {
	color: $light-steel-blue;
	font-size: .85rem;
	margin: .35rem 0 0;

	&:hover {
		color: darken($light-steel-blue, 5%);
	}
}