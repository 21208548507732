.grid-books--- {
	display: grid;
	position: relative;
	grid-auto-columns: 1fr;
	grid-column-gap: 1.5vw;
	grid-row-gap: 2rem;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;

	//@include media-breakpoint-up(sm) {
	//	grid-row-gap: 3rem;
	//	grid-template-columns: 1fr 1fr 1fr;
	//}
	@include media-breakpoint-up(sm) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(lg) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(xxl) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(xxxl) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	&.grid-books-sidebar {
		@include media-breakpoint-up(lg) {
			grid-row-gap: 3rem;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@include media-breakpoint-up(xxl) {
			grid-row-gap: 3rem;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
		//grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	&.has-property {

	}

	.book {
		.book-wrapper {
			position: relative;
			display: block;
			//height: 100%;
			//opacity: 1;
			//text-decoration: none;
			//cursor: pointer;
			//overflow: hidden;

			&:hover {
				.tools-buttons {
					transform: translateX(-10px);
				}
			}
		}

		.book-cover-wrapper {
			position: relative;
			margin: 0 0 .85rem 0;
			border-radius: .25rem;
			overflow: hidden;
			box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);
			//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), .25vmin 0.45vmin 1.5vmin rgba(0, 0, 0, 0.2);

			.img-overlay {
				&:after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					box-shadow: -0.125vmin -0.25vmin 0.5vmin rgba(0, 0, 0, 0.1) inset, -0.125vmin -0.125vmin 0.25vmin rgba(0, 0, 0, 0.2) inset;
					background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.2) 1vmin, rgba(0, 0, 0, 0) 1.25vmin);
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			.book-link {
				position: relative;
				display: block;
			}

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				max-width: 100%;
				//max-height: 16rem;

				transform: scale(1);
				transition: all 0.3s ease-in-out;

				&:hover {
					//box-shadow: 0 32px 40px -12px rgba(38, 38, 38, .15);
					transform: scale(1.07);
				}

			}

		}

		.book-author {
			font-size: .85rem;
			color: $grisaille;
		}

		.book-title {
			font-size: .95rem;
			color: #000;
			text-decoration: none;
			line-height: 1.15rem;
			//margin: 0 0 0.25rem 0;
			display: block;
		}

		.tools-buttons {
			list-style: none;
			transition: all 0.5s ease;
			position: absolute;
			right: 0;
			transform: translateX(50px);
			top: 10px;

			a {
				width: 2.2rem;
				height: 2.2rem;
				background: $ghost-white;
				border-radius: 100%;
				position: relative;
				text-decoration: none;
				font-size: 1.2rem;
				color: #000;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 0 .5rem 0;

				&:hover {
					background: $primary;
					color: #fff;
				}
			}

			> * {
				//transition: all 0.5s ease;
				//position: absolute;
				//right: 0;
				//transform: translateX(60px);
			}
		}

		&:hover {
			.book-title {
				color: $primary;
			}

			img {
				transform: scale(1.07);
			}
		}
	}
}

.books-grid {
	display: grid;
	//position: relative;
	//grid-auto-columns: 1fr;
	grid-column-gap: 1.5vw;
	grid-row-gap: 2rem;
	grid-template-columns: 1fr 1fr;
	//grid-template-rows: auto;

	@include media-breakpoint-up(sm) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(lg) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	@include media-breakpoint-up(xxl) {
		grid-row-gap: 3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	}

	.book {
		position: relative;

		&:hover {
			.book-title {
				color: $primary;
			}

			img {
				transform: scale(1.07);
			}

			.tools-buttons {
				transform: translateX(-10px);
			}
		}
	}


	.book-wrapper {
		position: relative;
		display: block;
	}

	.book-cover-wrapper {
		position: relative;
		//padding: 1rem;
		margin: 0 0 .85rem 0;
		border-radius: .25rem;
		overflow: hidden;
		//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);

		//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), .25vmin 0.45vmin 1.5vmin rgba(0, 0, 0, 0.2);

		.img-overlay {
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				box-shadow: -0.125vmin -0.25vmin 0.5vmin rgba(0, 0, 0, 0.1) inset, -0.125vmin -0.125vmin 0.25vmin rgba(0, 0, 0, 0.2) inset;
				//background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.2) 1vmin, rgba(0, 0, 0, 0) 1.25vmin);
				//background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.1) 1vmin, rgba(0, 0, 0, 0) .95vmin);

				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.1) 1vmin, rgba(0, 0, 0, 0) .95vmin);
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.book-link {
			position: relative;
			display: block;
			box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);
			border-radius: 0.25rem;
		}

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			max-width: 100%;
			//max-height: 16rem;

			transform: scale(1);
			transition: all 0.3s ease-in-out;

			&:hover {
				//box-shadow: 0 32px 40px -12px rgba(38, 38, 38, .15);
				transform: scale(1.07);
			}

		}

	}

	.book-title {
		font-size: .85rem;
		color: #000;
		text-decoration: none;
		line-height: 1.15rem;
		//margin: 0 0 0.25rem 0;
		display: block;
	}

	.book-author {
		font-size: .85rem;
		color: $grisaille;
	}

	.tools-buttons {
		list-style: none;
		transition: all 0.5s ease;
		position: absolute;
		right: 0;
		transform: translateX(50px);
		top: 10px;

		.tool-btn {
			width: 2.2rem;
			height: 2.2rem;
			background: $ghost-white;
			border-radius: 100%;
			border: 0;
			position: relative;
			text-decoration: none;
			font-size: 1.2rem;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 .5rem 0;

			> * {
				pointer-events: none;
			}

			&.active {
				color: $primary;
			}

			&:hover {
				background: $primary;
				color: #fff;
			}
		}

		> * {
			//transition: all 0.5s ease;
			//position: absolute;
			//right: 0;
			//transform: translateX(60px);
		}
	}

	.book-properties {
		display: block;
		width: calc(100% + 2px);
		position: absolute;
		left: -1px;
		padding: 1rem;
		border: 1px solid #dce1e6;
		border-top: 0;
		background: #fff;
		border-radius: 0 0 .25rem .25rem;
		font-size: 0.75rem;
		color: #8297b1;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s, opacity 0.3s linear;
	}

	&.has-sidebar {
		@include media-breakpoint-up(lg) {
			grid-row-gap: 1rem;
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
		@include media-breakpoint-up(xxl) {
			grid-row-gap: 1rem;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
		//grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}


	&.has-properties {
		grid-column-gap: .25vw;
		grid-row-gap: 0;
		@include media-breakpoint-up(xxl) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}

		.book {
			//position: relative;


			&:hover {
				.book-wrapper {
					border-color: #dce1e6;
					z-index: 50;
				}

				.book-properties {
					visibility: visible;
					opacity: 1;
				}
			}
		}

		.book-wrapper {
			border-radius: .25rem .25rem 0 0;
			border: 1px solid transparent;
			border-bottom: 0;
			transition: all 0.1s ease-in-out;
			padding: 1rem;
		}

		&.has-sidebar {

			@include media-breakpoint-up(xxl) {
				//grid-row-gap: 3rem;
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
			}
		}
	}
}


.section-headline {
	//font-size: 2rem;
	//font-weight: 700;
	//margin: 0 0 2rem 0;
}

.open-filter-offcanvas {
	display: flex;
	align-items: center;
	position: fixed;
	left: 1rem;
	bottom: 1rem;
	background: #fff;
	border: 0;
	border-radius: 2rem;
	padding: .45rem 1.25rem;
	//color: #000;
	z-index: 99;

	&:hover {
		color: $primary;
	}
}


.slide-books {
	//position: relative;

	//@include media-breakpoint-up(lg) {
	//	.swiper-button-prev,
	//	.swiper-button-next {
	//		visibility: hidden;
	//		transition: visibility .5s;
	//	}
	//
	//	&:hover {
	//		.swiper-button-prev,
	//		.swiper-button-next {
	//			visibility: visible;
	//		}
	//	}
	//}

	.book {

		max-width: 153.25px;
		margin-right: 10px;
		@include media-breakpoint-up(sm) {
		}

		&:hover {
			.book-title {
				color: $primary;
			}

			img {
				transform: scale(1.07);
			}

			.tools-buttons {
				transform: translateX(-10px);
			}
		}
	}


	.book-wrapper {
		position: relative;
		display: block;
	}

	.book-cover-wrapper {
		position: relative;
		//padding: 1rem;
		margin: 0 0 .85rem 0;
		border-radius: .25rem;
		overflow: hidden;
		//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);

		//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), .25vmin 0.45vmin 1.5vmin rgba(0, 0, 0, 0.2);

		.img-overlay {
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				box-shadow: -0.125vmin -0.25vmin 0.5vmin rgba(0, 0, 0, 0.1) inset, -0.125vmin -0.125vmin 0.25vmin rgba(0, 0, 0, 0.2) inset;
				//background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.2) 1vmin, rgba(0, 0, 0, 0) 1.25vmin);
				//background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.1) 1vmin, rgba(0, 0, 0, 0) .95vmin);

				background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.5vmin, rgba(0, 0, 0, 0.1) 1vmin, rgba(0, 0, 0, 0) .95vmin);
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		.book-link {
			position: relative;
			display: block;
			box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);
			border-radius: 0.25rem;
		}

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			max-width: 100%;
			//max-height: 16rem;

			transform: scale(1);
			transition: all 0.3s ease-in-out;

			&:hover {
				//box-shadow: 0 32px 40px -12px rgba(38, 38, 38, .15);
				transform: scale(1.07);
			}

		}

	}

	.book-title {
		font-size: .85rem;
		color: #000;
		text-decoration: none;
		line-height: 1.15rem;
		//margin: 0 0 0.25rem 0;
		display: block;
	}

	.book-author {
		font-size: .85rem;
		color: $grisaille;
	}

	.tools-buttons {
		list-style: none;
		transition: all 0.5s ease;
		position: absolute;
		right: 0;
		transform: translateX(50px);
		top: 10px;

		.tool-btn {
			width: 2.2rem;
			height: 2.2rem;
			background: $ghost-white;
			border-radius: 100%;
			border: 0;
			position: relative;
			text-decoration: none;
			font-size: 1.2rem;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 .5rem 0;
			padding: 0;

			> * {
				pointer-events: none;
			}

			&.is-loading {
				opacity: 1;

				&:before {
					background: $primary;
				}

				i {
					opacity: .1;
				}
			}

			&.active {
				color: $primary;
			}

			&:hover {
				background: $primary;
				color: #fff;
			}
		}

		//a {
		//	width: 2.2rem;
		//	height: 2.2rem;
		//	background: $ghost-white;
		//	border-radius: 100%;
		//	position: relative;
		//	text-decoration: none;
		//	font-size: 1.2rem;
		//	color: #000;
		//	display: flex;
		//	justify-content: center;
		//	align-items: center;
		//	margin: 0 0 .5rem 0;
		//
		//	&:hover {
		//		background: $primary;
		//		color: #fff;
		//	}
		//}

		> * {
			//transition: all 0.5s ease;
			//position: absolute;
			//right: 0;
			//transform: translateX(60px);
		}
	}
}

.slide-items-horizontal {
	position: relative;
	@include media-breakpoint-down(sm) {
		padding: 0 2rem;
	}

	.swiper-button-prev,
	.swiper-rtl .swiper-button-next {
		left: 0;
		@include media-breakpoint-up(sm) {
			left: -30px;
		}
		@include media-breakpoint-up(lg) {
			left: -35px;
		}
	}

	.swiper-button-next,
	.swiper-rtl .swiper-button-prev {
		right: 0;
		@include media-breakpoint-up(sm) {
			right: -30px;
		}
		@include media-breakpoint-up(lg) {
			right: -35px;
		}
	}
}

.cat-page-header {
	background-image: linear-gradient(185deg, #f5f7fa 0%, #f0f3f8 100%);
	//background-image: linear-gradient(115deg, #f3f4f7 0%, #f5f9fa 74%);

	.cat-type {
		display: inline-block;
		padding: 0.45em 0.95em;
		font-size: 0.75em;
		font-weight: 700;
		line-height: 1;
		color: #858c97;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25rem;
		background: #fff;
	}

	.cat-description {
		font-size: 0.95rem;
		margin: .5rem 0;
		&.is-hidden {
			max-height: 4rem;
			overflow: hidden;
		}
	}

	.cat-image {
		width: 85px;
		height: 85px;
		font-size: 1.25rem;
		max-width: 100%;
		overflow: hidden;
		margin: 0 auto;


		&.initials {
			border-radius: .25rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			letter-spacing: 0.06em;
			font-weight: 600;
			//background: #839afd;
			background-color: #839afd;
			//background-image: linear-gradient(315deg, #839afd 0%, #a4bfef 74%);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.cat-title {
		font-size: 1.65rem;
		font-weight: 500;
		margin: .5rem 0;

		//@include media-breakpoint-down(lg) {
		//	text-align: center;
		//}
	}
}

.cat-page-container {
	padding: 3rem 0;
	border-radius: .25rem;
	//background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
	//background-image: linear-gradient(185deg, #f5f7fa 0%, #f0f3f8 100%);
	//background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
	//background-image: linear-gradient(147deg, #f9fcff 0%, #dee4ea 74%);
	//background-image: linear-gradient(315deg, #dde2ea 0%, #f5f7fa 74%);
	//background-image: linear-gradient(115deg, #f3f4f7 0%, #f5f9fa 74%);
	//background-image: linear-gradient(147deg, #f9fcff 0%, #fdfdfd 74%);
}


.user-book-lists {

	.user-book-list {
		position: relative;

		&.is-modal {
			.book-list-cover-wrapper {
				height: 10rem;


			}

			.book-list-action-btn {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1020;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.75);
					opacity: 0;
					visibility: hidden;
					transition: 0.3s linear;
					border-radius: 0.25rem;
				}
			}

			&:hover {
				cursor: pointer;

				.book-list-cover-wrapper {
					.book-list-icon {
						visibility: visible;
						opacity: 1;
					}


				}

				.book-list-action-btn {
					&:after {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

		&:hover {
			img {
				transform: scale(1.07);
			}

			.tools-buttons {
				transform: translateX(-10px);
				visibility: visible;
			}
		}
	}

	.book-list-wrapper {
		position: relative;
		display: block;
	}

	.book-list-cover-wrapper {
		position: relative;
		margin: 0 0 .85rem 0;
		border-radius: .25rem;
		overflow: hidden;
		height: 14rem;


		.book-list-link {
			position: relative;
			display: block;
			//box-shadow: 0.125vmin 0.125vmin 0.5vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.25vmin 1vmin rgba(0, 0, 0, 0.1), 0.25vmin 0.45vmin 1.15vmin rgba(0, 0, 0, 0.1);
			border-radius: 0.25rem;
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
			max-width: 100%;
			//max-height: 16rem;

			transform: scale(1);
			transition: all 0.3s ease-in-out;

			&:hover {
				//box-shadow: 0 32px 40px -12px rgba(38, 38, 38, .15);
				transform: scale(1.07);
			}

		}

	}

	.tools-buttons {
		list-style: none;
		transition: all 0.5s ease;
		position: absolute;
		right: 0;
		transform: translateX(50px);
		top: 10px;
		//opacity: 0;
		visibility: hidden;

		.tool-btn {
			width: 2.2rem;
			height: 2.2rem;
			background: $ghost-white;
			border-radius: 100%;
			border: 0;
			position: relative;
			text-decoration: none;
			font-size: 1.2rem;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 .5rem 0;
			padding: 0;

			> * {
				pointer-events: none;
			}

			&.is-loading {
				opacity: 1;

				&:before {
					background: $primary;
				}

				i {
					opacity: .1;
				}
			}

			&.active {
				color: $primary;
			}

			&:hover {
				background: $primary;
				color: #fff;
			}
		}

	}

	.private-list {
		position: absolute;
		bottom: 4px;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		background: $ufo-green;
		color: #fff;
		padding: .05rem .65rem;
		border-radius: .25rem;
		font-size: .85rem;
		display: flex;
		align-items: center;

		i {
			font-size: 1rem;
		}
	}

	.book-in-list {
		position: absolute;
		top: 5px;
		right: 5px;
		white-space: nowrap;
		background: $ufo-green;
		color: #fff;
		border-radius: 50%;
		font-size: 0.85rem;
		display: flex;
		align-items: center;
		width: 1.85rem;
		height: 1.85rem;
		justify-content: center;

		i {
			font-size: 1rem;
		}
	}

	.book-list-action-btn {
		width: 100%;
		height: 100%;
		//z-index: 1030;

		&.add {
			.book-list-icon {
				color: $ufo-green;
			}
		}

		&.delete {
			.book-list-icon {
				color: $watermelon;
			}
		}

		> * {
			pointer-events: none;
		}
	}

	.book-list-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1030;
		transform: translate(-50%, -50%);
		pointer-events: none;
		text-align: center;
		width: 100%;
		visibility: hidden;
		opacity: 0;


		i {
			display: block;
			font-size: 2.5rem;
			line-height: 1;
		}

		span {
			color: #fff;
			font-size: .85rem;
		}
	}

}

.add-book-list-btn {
	position: relative;
	margin: 0 0 .85rem 0;
	border-radius: .25rem;
	overflow: hidden;
	height: 10rem;

	.icon {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1030;
		transform: translate(-50%, -50%);
		pointer-events: none;
		text-align: center;
		width: 100%;
		color: $ufo-green;

		i {
			display: block;
			font-size: 2.5rem;
			line-height: 1;
		}
	}

	form {
		position: relative;
		padding: 2.4rem 1rem 1rem;
		border-radius: .45rem;
		z-index: 1055;
		width: 100%;
		height: 100%;
		display: none;

		.close-btn {
			position: absolute;
			top: 3px;
			right: 0;
			color: $prestige-blue;
			transition: all 0.3s;
			font-size: 1.25rem;
			background: none;
			border: 0;

			> * {
				pointer-events: none;
			}

			&:hover {
				color: $primary;
			}
		}
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1020;
		width: 100%;
		height: 100%;
		background: rgba(237, 241, 244, 0.95);
		opacity: 0;
		visibility: hidden;
		transition: 0.3s linear;
		border-radius: 0.25rem;
	}

	&:hover {
		cursor: pointer;
		background: $ghost-white-v2;

		//&:before {
		//	opacity: 0;
		//	visibility: hidden;
		//}
	}

	&.is-visible {
		&:hover {
			cursor: default;
		}

		&:before {
			opacity: 1;
			visibility: visible;
		}

		.icon {
			z-index: 1010;
		}

		form {
			display: block;
		}
	}
}

/*
.card-book-list {
	height: 14rem;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	overflow: hidden;

	.private-list {
		position: absolute;
		bottom: 4px;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		background: $ufo-green;
		color: #fff;
		padding: .05rem .65rem;
		border-radius: .25rem;
		font-size: .85rem;
		display: flex;
		align-items: center;

		i {
			font-size: 1rem;
		}
	}

	.book-in-list {
		//position: absolute;
		//top: 0;
		//left: 50%;
		//transform: translateX(-50%);
		//white-space: nowrap;
		//background: #3ed77a;
		//color: #fff;
		//padding: .05rem .65rem;
		//border-radius: .25rem;
		//font-size: .85rem;
		//display: flex;
		//align-items: center;

		position: absolute;
		top: 5px;
		right: 5px;
		white-space: nowrap;
		background: $ufo-green;
		color: #fff;
		border-radius: 50%;
		font-size: 0.85rem;
		display: flex;
		align-items: center;
		width: 1.85rem;
		height: 1.85rem;
		justify-content: center;

		i {
			font-size: 1rem;
		}
	}

	.tools-buttons {
		list-style: none;
		transition: all 0.5s ease;
		position: absolute;
		right: 0;
		transform: translateX(50px);
		top: 10px;

		.tool-btn {
			width: 2.2rem;
			height: 2.2rem;
			background: $ghost-white;
			border-radius: 100%;
			border: 0;
			position: relative;
			text-decoration: none;
			font-size: 1.2rem;
			color: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 0 .5rem 0;
			padding: 0;

			> * {
				pointer-events: none;
			}

			&.is-loading {
				opacity: 1;

				&:before {
					background: $primary;
				}

				i {
					opacity: .1;
				}
			}

			&.active {
				color: $primary;
			}

			&:hover {
				background: $primary;
				color: #fff;
			}
		}

		//a {
		//	width: 2.2rem;
		//	height: 2.2rem;
		//	background: $ghost-white;
		//	border-radius: 100%;
		//	position: relative;
		//	text-decoration: none;
		//	font-size: 1.2rem;
		//	color: #000;
		//	display: flex;
		//	justify-content: center;
		//	align-items: center;
		//	margin: 0 0 .5rem 0;
		//
		//	&:hover {
		//		background: $primary;
		//		color: #fff;
		//	}
		//}

		> * {
			//transition: all 0.5s ease;
			//position: absolute;
			//right: 0;
			//transform: translateX(60px);
		}
	}

	&:hover {
		.tools-buttons {
			transform: translateX(-10px);
		}
	}
}

.card-book-list-modal {
	height: 10rem;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1020;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.75);
		opacity: 0;
		visibility: hidden;
		transition: 0.3s linear;
		border-radius: 0.25rem;
	}

	.book-list-action-btn {
		visibility: hidden;
		opacity: 0;
		width: 100%;
		height: 100%;
		z-index: 1030;

		> * {
			pointer-events: none;
		}
	}

	.book-list-icon {

		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 1030;
		transform: translate(-50%, -50%);
		pointer-events: none;
		text-align: center;
		width: 100%;

		&.green {
			color: $ufo-green;
		}

		&.red {
			color: $watermelon;
		}

		i {
			display: block;
			font-size: 2.5rem;
			line-height: 1;
		}

		span {
			color: #fff;
			font-size: .85rem;
		}
	}

	&:hover {
		cursor: pointer;

		.book-list-action-btn {
			visibility: visible;
			opacity: 1;
		}

		&:before {
			opacity: 1;
			visibility: visible;
		}
	}
}

.card-add-book-list {
	&:hover {
		background: $ghost-white-v2;

		&:before {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.is-overlay {
		&:before {
			background: rgba(237, 241, 244, 0.95);
			opacity: 1;
			visibility: visible;
		}
	}

	.book-list-icon {
		z-index: 1010;
	}
}
*/