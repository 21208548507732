.btn-social {
	display: inline-block;
	background-color: $ghost-white-v2;
	color: $light-steel-blue;
	width: 2.25rem;
	height: 2.25rem;
	padding: 0;
	border: 1px solid transparent;
	border-radius: .25rem;
	font-size: 1.35rem;
	text-align: center;
	text-decoration: none !important;
	transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out, color 0.25s ease-in-out;

	> i {
		line-height: 2.125rem;
	}

	&:hover {
		color: #fff;

		&.btn-twitter {
			background-color: $twitter-color;
		}

		&.btn-vk {
			background-color: $vk-color;
		}

		&.btn-facebook {
			background-color: $facebook-color;
		}

		&.btn-google {
			background-color: $google-color;
		}

		&.btn-youtube {
			background-color: $youtube-color;
		}

		&.btn-instagram {
			background-color: $instagram-color;
		}

		&.btn-linkedin {
			background-color: $linkedin-color;
		}

	}
}

//.btn {
//	i {
//		position: relative;
//		top: .1rem;
//		font-size: 1rem;
//	}
//}


/******************************************************************************/
.btn {
	transition: all .2s ease;

	//> i {
	//	margin-top: -.1875rem;
	//	vertical-align: middle;
	//}

	&.is-loading {
		transition: all 0.3s ease-in-out;
		color: transparent;
		position: relative;

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			content: '';
			height: .5rem;
			width: .5rem;
			background: #fff;
			border-radius: 100%;
			transition: all 0.4s linear;
			animation: left-right-bounce 1s infinite;
			opacity: 1;
			transform: translateY(-50%) translateX(-50%);
		}

		&.btn-white {
			&:before {
				background: $primary;
			}
		}
	}

	&.dropdown-toggle {
		display: inline-flex;
		align-items: center;
	}
}

@keyframes left-right-bounce {
	0% {
		left: 30%;
	}
	50% {
		left: 70%;
	}
	100% {
		left: 30%;
	}
}

.btn-icon {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-weight: 400;
	width: 2rem;
	height: 2rem;
	padding: 0;

	&.btn-xs {
		font-size: .75rem;
		width: 1.75rem;
		height: 1.75rem;
	}


	&.btn-white {
		&:hover {
			color: $primary;
		}
	}
}

.btn-white {
	color: $prestige-blue;
	background-color: #fff;
	border-color: #fff;
	box-shadow: none;
}

.btn-outline-white {
	background-color: #fff;
	border-color: $border-color;
}

.btn-outline-white.active,
.btn-outline-white:focus,
.btn-outline-white:hover {
	color: $primary;
	box-shadow: 0 3px 6px -2px rgba(140, 152, 164, .25);
}

.btn-like, .btn-dislike {
	display: inline-flex;
	align-items: center;
	transition: color .25s ease-in-out;
	border: 0;
	background: none !important;
	color: $light-slate-gray;
	font-size: .975rem;
}

.btn-like:hover {
	color: $success;
}

.btn-dislike:hover {
	color: $danger;
}

.hover-translate-y-n3:hover,
.translate-y-n3 {
	transform: translateY(-3px) !important;
}

.btn-xs {
	font-size: .75rem;
	font-weight: 400;
	padding: .275rem .75rem;
}

.btn-rock-blue-outline {
	color: $rock-blue;
}

.btn-soft-rock-blue {
	color: #95A6C1;
	background-color: #F4F6F9;
}

.gi.is-loading {
	animation-name: icon-spin;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;

	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;

	-webkit-mask-image: -webkit-radial-gradient(white, black);
	mask-image: radial-gradient(white, black);
	opacity: 0.899;
	will-change: transform;
}

@keyframes icon-spin {
	0% {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(1turn);
	}
}
