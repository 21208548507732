@keyframes scale-dropdown {
	0% {
		transition: 0.3s cubic-bezier(.2, 0, 0, 1.6);
		transform: scale(0.6) translateY(-20%);
	}
	100% {
		transform: none;
	}
}

.dropdown-menu {
	&.dropdown-menu-sm {
		min-width: 10rem;
	}
	&.dropdown-menu-md {
		min-width: 16rem;
	}

	&.animate {
		transition: all 0.3s cubic-bezier(.2, 0, 0, 1.6);

		&.show {

		}

		&[data-bs-popper] {
			animation: scale-dropdown 0.3s cubic-bezier(.2, 0, 0, 1.6);
		}
	}
}

.dropdown-menu-arrow {
	&:before {
		content: "";
		background: #fff;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: block;
		height: 16px;
		width: 16px;
		left: 1.25rem;
		position: absolute;
		z-index: -5;
		border-radius: .2rem;
	}

	&:before {
		bottom: 100%;
		-webkit-transform: rotate(-45deg) translateY(1rem);
		transform: rotate(-45deg) translateY(1rem);
	}

	&[data-popper-placement="top-end"]::before {
		bottom: 3px;
		-webkit-transform: rotate(-45deg) translateY(1rem);
		transform: rotate(-45deg) translateY(1rem);
	}

	&.dropdown-menu-end {
		&::before {
			right: 2.5rem;
			left: auto;
		}
		&.dropdown-menu-sm {
			&::before {
				right: 2rem;
			}
		}
	}
}