.book-img-wrapper {
	position: relative;
	overflow: hidden;
	padding: 4rem 1rem 3rem 1rem;
	color: #fff;

	a {
		//color: #fff;

		&:hover {
			color: $primary;
		}
	}
}

.svg-blur {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.book-img-bg {
	position: absolute;
	//width: calc(100% + 10 * 1.5rem);
	//height: calc(100% + 4 * 1.5rem);
	//margin: -8.5rem 0 0 -12.5rem;

	z-index: -1;
	top: -1%;
	bottom: -1%;
	left: -1%;
	right: -1%;
	width: 102%;
	height: 102%;
	margin: -2%;
	transform: scale(1.1);
	//filter: blur(20px) brightness(.5);
	//filter: blur(20px)contrast(.9) sepia(.2);
	//filter: blur(20px) sepia(0.22) brightness(0.3) contrast(0.85) saturate(0.75);
	//filter: blur(20px)hue-rotate(180deg)brightness(0.85);
	filter: blur(20px) brightness(0.5) opacity(.95);
	filter: url("#svg-blur") brightness(0.5) opacity(.95);
	//filter: blur(20px) brightness(0.5) brightness(1.65);

	//filter: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%3E%3Cdefs%3E%3Cfilter%20id%3D%22blur%22%3E%3CfeGaussianBlur%20stdDeviation%3D%225%22%2F%3E%3C%2Ffilter%3E%3C%2Fdefs%3E%3C%2Fsvg%3E#blur");
	//filter: blur(25px);
	// Orange
	//background-image: url('https://img.freepik.com/free-photo/abstract-luxury-soft-red-background-christmas-valentines-layout-design-studio-room-web-template-business-report-with-smooth-circle-gradient-color_1258-54172.jpg?size=626&ext=jpg&uid=R6715360&ga=GA1.2.769921762.1625138594');
	//// Black
	//background-image: url('https://img.freepik.com/free-photo/abstract-luxury-blur-dark-grey-black-gradient-used-as-background-studio-wall-display-your-products_1258-48265.jpg?size=626&ext=jpg&uid=R6715360&ga=GA1.2.769921762.1625138594');
	//
	//background-image: url('https://img.freepik.com/free-photo/abstract-background-colorful-spin-circle_73152-3318.jpg?size=626&ext=jpg&uid=R6715360&ga=GA1.2.769921762.1625138594');
	//
	//background-image: url('https://img.freepik.com/free-photo/colorful-abstract-nebula-space-background_53876-108663.jpg?size=626&ext=jpg&uid=R6715360&ga=GA1.2.769921762.1625138594');
	//background: linear-gradient(90deg, #42557d, #465575, #48546d, #4a5365, #4a505d, #4a4d55, #48494d, #454545);
	//background: linear-gradient(180deg, #41547d, #43568d, #45579d, #4656ad, #4954bc, #5257c5, #5e5ccd, #6e66d5);
	background: #242425 linear-gradient(180deg, #41547d, #425273, #43506a, #444d61, #444b59, #444851, #434549, #424242) no-repeat center;
	background-size: cover;
}

.book-info-container {
	//display: flex;
	//flex-wrap: wrap;
	//margin: 0 0 2rem;

	//--bs-gutter-x: 1.5rem;
	//--bs-gutter-y: 0;
	//display: flex;
	//flex-wrap: wrap;
	//margin-top: calc(var(--bs-gutter-y) * -1);
	//margin-right: calc(var(--bs-gutter-x) * -.5);
	//margin-left: calc(var(--bs-gutter-x) * -.5);

	//> * {
	//	flex-shrink: 0;
	//	width: 100%;
	//	max-width: 100%;
	//	padding-right: calc(var(--bs-gutter-x) * .5);
	//	padding-left: calc(var(--bs-gutter-x) * .5);
	//	margin-top: var(--bs-gutter-y);
	//}

	.book-author {
		a {
			color: #fff;
		}
	}

	@include media-breakpoint-up(lg) {
		//max-height: 34rem;
		//overflow: hidden;
	}
}

.book-property-list {
	padding: 0.325rem;

	.property-item {
		margin: 0 0 1rem 0;

		.property-label {
			color: #fff;
			text-transform: uppercase;
			letter-spacing: 0.085rem;
			font-weight: 700;
			font-size: 0.75rem;
			margin: 0 0 .5rem 0;
		}

		.property-value {
			color: #ecf0f1;
			font-size: .85rem;

			a {
				color: #fff;

				&:hover {
					color: $primary;
				}
			}
		}
	}
}

.book-media {
	display: flex;
	//flex: 0 0 auto;
	////width: 375px;
	//width: 25%;

	&.has-gallery {
		//width: 33.33333333%;
	}

	.book-images-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: .325rem;
		padding-right: .5rem !important;
		max-height: 31rem;
		width: 6rem;
		max-width: 6rem;
		overflow: hidden;
		//@include media-breakpoint-up(sm) {
		//	margin-right: .625rem;
		//}

	}

	.book-gallery-item {
		display: block;
		position: relative;
		padding: 0.325rem;
		width: 100%;
		transition: all 0.2s ease-in-out;
		border-radius: 0.25rem;
		text-decoration: none !important;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			border-radius: 0.25rem;
			transition: opacity .2s ease-in-out;
			opacity: .85;
		}

		&:hover {
			img {
				cursor: pointer;
				opacity: 1;
			}
		}
	}

	.book-cover-container {
		position: relative;
		width: 100%;

		img {
			max-width: 100%;
			height: auto;
			@include media-breakpoint-up(xl) {
				object-fit: contain;
				max-height: 32rem;
			}
		}
	}


}

.book-details {
	//flex: 0 0 auto;
	//width: 66.66666667%;
}

.book-rating {
	display: inline-block;
	white-space: nowrap;
	line-height: 1;
	vertical-align: middle;

	i {
		display: inline-block;
		margin-right: .1875rem;
		color: $star-color;
		font-size: $star-size;
		vertical-align: middle;

		&.active {
			color: $star-active-color;
		}
	}
}

.book-description {
	@include media-breakpoint-up(lg) {
		height: 10rem;
		overflow: hidden;
	}
}

.book-actions {
	display: flex;
	flex-wrap: wrap;
	margin: 1.85rem 0 1.5rem;
	//justify-content: space-between;
	//@include media-breakpoint-up(lg) {
	//	max-width: 70%;
	//}

	.book-action {
		text-align: center;
		font-size: .85rem;
		margin-right: .95rem;
		color: $b-action-color;
		background: transparent;
		padding: 0;
		border: 0;
		transition: transform .15s;

		> * {
			pointer-events: none;
		}

		&.is-loading {
			&:before {
				background: $primary;
			}
		}

		i {
			display: block;
			font-size: 2.55rem;
			margin-bottom: .5rem;
			line-height: 2.55rem;
		}

		&:hover {
			transform: translate3d(0, -2px, 0);
			color: $b-action-hover-color;
		}

		@include media-breakpoint-up(md) {
			margin-right: 2.25rem;
		}
	}

	.download-book-menu {
		min-width: 10rem;
	}
}

.sign-in-link {
	color: $body-color;
	text-decoration: dashed underline;
	text-underline-offset: 3px;
}