//.text-overflow {
//  overflow-x: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//  display: block;
//}
//
//.tx-echo-blue {
//  color: $echo-blue-color;
//}

.text-rock-blue {
  color: $rock-blue;
}
.text-cello {
  color: $cello-color;
}
.text-light-steel-blue {
  color: $light-steel-blue;
}
.text-grisaille {
  color: $grisaille;
}
//.text-facebook {
//  color: #1877F2 !important;
//}
//
//.text-google {
//  color: #FF0000 !important;
//}
//
//.text-twitter {
//  color: #1DA1F2 !important;
//}
//
//.text-east-bay {
//  color: $east-bay;
//}
//
//.text-manatee {
//  color: $manatee;
//}
//
//.fs-7 {
//  font-size: .9375rem !important
//}
//
//.fs-8 {
//  font-size: .875rem !important
//}
//
//.fs-9 {
//  font-size: .8125rem !important
//}
//
//.fs-10 {
//  font-size: .75rem !important
//}
//
//.tx-gray-100 {
//  color: $gray-100;
//}
//
//.tx-gray-200 {
//  color: $gray-200;
//}
//
//.tx-gray-300 {
//  color: $gray-300;
//}
//
//.tx-gray-400 {
//  color: $gray-400;
//}
//
//.tx-gray-500 {
//  color: $gray-500;
//}
//
//.tx-gray-600 {
//  color: $gray-600;
//}
//
//.tx-gray-700 {
//  color: $gray-700;
//}
//
//.tx-gray-800 {
//  color: $gray-800;
//}
//
//.tx-gray-900 {
//  color: $gray-900;
//}