.subscriptions-page {
	padding: 6rem 0;
	background: #f4f7fb;

}

.plan-pricing-card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border-top: 0.5rem solid #e6ecf5;
	transition: transform .5s ease;

	&:hover {
		transform: translateY(-3px);
	}

	.plan-name {
		color: #000;
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 1.65rem;
	}

	.plan-description {
		font-size: .925rem;
		line-height: 1.3;
		margin-bottom: 1.85rem;
	}

	.plan-price {
		color: #000;
		font-size: 2.25rem;
		font-weight: 400;
		line-height: 1.2;

	}

	.plan-period {
		color: $bay-wharf;
		font-size: .925rem;
		line-height: 1.3;
		font-weight: 400;
		margin-bottom: 1.85rem;
	}

	//.card-header {
	//	position: relative;
	//
	//	&:after {
	//		content: '';
	//		display: block;
	//		width: 80%;
	//		position: absolute;
	//		bottom: 0;
	//		left: 50%;
	//		margin-left: -40%;
	//		height: 1px;
	//		background: radial-gradient(ellipse at center, #d1dbe7 0, rgba(255, 255, 255, 0) 75%);
	//	}
	//}

	.property-list {
		.property-item {
			display: flex;
			align-items: center;
			font-size: .95rem;
			padding: 0 0 .45rem;

			i {
				color: $success;
				font-size: 1.25rem;
				margin-right: .5rem;
			}
		}
	}

}

.period-selector {
	display: flex;
	margin: 2rem 0 0;
	overflow: hidden;
	justify-content: center;

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;

		&:checked + label {
			background-color: $info;
			color: #fff;
			box-shadow: none;
		}
	}

	label {
		background-color: #f5f9fa;
		color: #000;
		font-size: .85rem;
		font-weight: 500;
		line-height: 1;
		text-align: center;
		padding: .75rem 1.75rem;
		margin-right: -1px;
		border: 1px solid transparent;
		//box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
			background: #FAFCFD;
		}

		&:first-of-type {
			border-radius: .35rem 0 0 .35rem;
		}

		&:last-of-type {
			border-radius: 0 .35rem .35rem 0;
		}
	}
}

/*
.subscription-checkout--- {

	.payment-methods {
		.nav-link {
			display: flex;
			align-items: center;
			font-weight: 500;
			line-height: 1.5;
			color: #C4CDE2;
			text-align: center;
			vertical-align: middle;
			cursor: pointer;
			user-select: none;
			background-color: transparent;
			border: 1px solid transparent;
			padding: 0.45rem 1.15rem;
			font-size: .95rem;
			border-radius: .275rem;
			transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

			i {
				font-size: 1.85rem;
			}

			&:hover {
				color: $international-orange-color;
			}

			&.active {
				color: #9cabcf;
				background: #f4f9ff;
			}
		}
	}

	.subscription-info {
		background: #f4f9ff;

		//.heading {
		//	font-weight: 700;
		//	margin: 0 0 1rem;
		//	font-size: 1.05rem;
		//	text-transform: uppercase;
		//}

		.s-period {
			//font-size: .75rem;
		}

		.s-price {
			//font-weight: 600;
		}

		.s-total-price {
			//font-weight: 600;
			//font-size: 1.45rem;
		}
	}
}
*/

/*
.stripe-form--- {
	//margin: 2rem 0;

	.form-label {
		font-weight: 600;
		margin: .85rem 0 .3rem;
	}

	.field {
		width: 100%;
		height: 2.6rem;
		padding: .65rem 1rem;
		font-size: 1rem;
		color: #000;
		background-color: #fff;
		background-image: none;
		border: 1px solid #e8ebef;
		border-radius: .25rem;
		box-shadow: rgba(61, 61, 61, 0.1) 0 1px 2px 0;
		transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	}

	.field.focus,
	.field:focus {
		color: #424770;
		background-color: #f6f9fc;
	}

	.field.invalid {
		//background-color: #f16e54;
		border-color: #f16e54;
	}

	.field.invalid.focus {
		background-color: #f6f9fc;
	}

	.field.focus::-webkit-input-placeholder,
	.field:focus::-webkit-input-placeholder {
		color: green;
	}

	.field.focus::-moz-placeholder,
	.field:focus::-moz-placeholder {
		color: green;
	}

	.field.focus:-ms-input-placeholder,
	.field:focus:-ms-input-placeholder {
		color: green;
	}

	input, button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		border-style: none;
	}

	input {
		color: #000;
	}

	input::-webkit-input-placeholder {
		color: #9bacc8;
	}

	input::-moz-placeholder {
		color: #9bacc8;
	}

	input:-ms-input-placeholder {
		color: #9bacc8;
	}

	button {
		margin: 1rem 0;
		background-color: $international-orange-color;
		border-radius: 0.45rem;
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
		padding: 0.475rem 1.75rem;
	}

	button:hover {
		color: #ffede5;
	}

	button:active {
		background-color: $international-orange-color;
	}
}
*/


/*
.subscriptions {

}

.subscription-cards {
	margin: 3rem 0;

	.subscription-card {
		border: 0;
		border-radius: 0;
		-webkit-box-shadow: 0 3px 0 0 rgba(0, 0, 0, .08);
		box-shadow: 0 3px 0 0 rgba(0, 0, 0, .08);
		transition: all .3s ease-in-out;
		padding: 2.25rem 0;
		position: relative;
		will-change: transform;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 0%;
			height: 5px;
			background-color: $international-orange-color;
			transition: 0.5s;
		}

		&:hover {
			transform: scale(1.01);
			-webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, .08);
			box-shadow: 0 20px 35px 0 rgba(0, 0, 0, .08);

			&:after {
				width: 100%;
			}

		}

		.card-header {
			background-color: white;
			//padding-left: 2rem;
			border-bottom: 0;
		}

		.list-group-item {
			border: 0;
			padding: 0.35rem 1rem;
			color: #9e9eb3;
			font-weight: 400;
		}

		.title {
			margin-bottom: 1rem;
			font-weight: 700;
			font-size: 2.2rem;
		}

		.price {
			//font-size: 4rem;
			//letter-spacing: -0.5rem;
			//font-weight: 300;

			.sign {
				display: inline-block;
				font-size: 1.75rem;
				font-weight: 400;
				vertical-align: middle;
			}

			.currency {
				font-size: 4.75rem;
				font-weight: 300;
				letter-spacing: -.125rem;
				line-height: 4.75rem;
				padding: 0;
				vertical-align: middle;
			}

			.cent {
				display: inline-block;
				font-size: 1.8rem;
				font-weight: 400;
				vertical-align: bottom;
			}

			.period {
				color: #8f939d;
				font-size: 1.2rem;
				font-weight: 400;
				letter-spacing: .2rem;
				margin-top: -.3rem;
				display: block;
			}
		}

		.subscribe-btn {
			text-transform: uppercase;
			font-size: .75rem;
			//font-weight: 500;
			color: lighten(#808080, 15%);
			border-radius: 0;
			padding: .75rem 1.25rem;
			letter-spacing: .1rem;

			background-color: #f2f2f2;
			transition: background .3s ease-in-out;

			&:hover {
				color: white;
				background-color: $international-orange-color;
			}
		}
	}
}



.switch-field {
	display: flex;
	margin: 2rem 0 0;
	overflow: hidden;
	justify-content: center;

	input {
		position: absolute !important;
		clip: rect(0, 0, 0, 0);
		height: 1px;
		width: 1px;
		border: 0;
		overflow: hidden;

		&:checked + label {
			background-color: $international-orange-color;
			color: #fff;
			box-shadow: none;
		}
	}

	label {
		background-color: #f2f4f8;
		color: #000;
		font-size: .95rem;
		line-height: 1;
		text-align: center;
		padding: .5rem 1.25rem;
		margin-right: -1px;
		border: 1px solid transparent;
		//box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
		transition: all 0.1s ease-in-out;

		&:hover {
			cursor: pointer;
		}

		&:first-of-type {
			border-radius: .25rem 0 0 .25rem;
		}

		&:last-of-type {
			border-radius: 0 .25rem .25rem 0;
		}
	}
}


 */


.subscription-page {
	padding: 6rem 0;
	background: #f4f7fb;

	.card-shadow {
		box-shadow: 0 3px 0 0 #dfe6ee;
	}
}

.subscription-plan-selector {

	.subscription-plan-card {
		//background: var(--background);
		//height: 8rem;
		//width: 100%;
		border-radius: .35rem;
		overflow: hidden;
		position: relative;
		cursor: pointer;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.01);
		border: 2px solid #f4f6f8;
		transition: transform 0.15s, box-shadow 0.15s;
		transform: scale(1) translateZ(0);

		&:active {
			transform: scale(0.95) translateZ(0);
		}

		&.active {
			border: 2px solid $primary;

			.subscription-plan-icon {
				visibility: visible;
				opacity: 1;
			}

		}
	}

	.subscription-plan-body {
		padding: 1.6rem 1rem;
		text-align: center;
	}

	.subscription-plan-input {
		position: absolute;
		display: block;
		outline: none;
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		-webkit-appearance: none;
	}

	.subscription-plan-icon {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 1.15rem;
		width: 1.6rem;
		height: 1.6rem;
		border-radius: 50%;
		background: $primary;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: visibility 0.15s, opacity 0.15s;
		visibility: hidden;
		opacity: 0;
	}

	.subscription-plan-price {
		font-size: 1.2rem;
		font-weight: 600;
		letter-spacing: .05rem;
	}

	.subscription-plan-duration {
		font-size: .75rem;
		color: $bay-wharf;
		font-weight: 300;
		text-transform: uppercase;
	}
}

.payment-methods-selector {
	.nav-link {
		display: flex;
		align-items: center;
		font-weight: 500;
		line-height: 1.5;
		color: #C4CDE2;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		user-select: none;
		background-color: transparent;
		padding: 0.45rem 1.15rem;
		font-size: .95rem;
		border-radius: .275rem;
		transition: all .15s ease-in-out;
		border: 2px solid transparent;
		-webkit-filter: grayscale(100%) opacity(.8);
		filter: grayscale(100%) opacity(.8);

		i {
			font-size: 1.85rem;
		}

		svg {
			height: 50px;
			width: auto;
		}


		&:hover {
			color: $international-orange-color;
		}

		&.active {
			//color: #9cabcf;
			//background: #f4f9ff;

			-webkit-filter: grayscale(0%) opacity(1);
			filter: grayscale(0%) opacity(1);

			//fill: #9cabcf;
			border: 2px solid #f4f6f8;
		}
	}

	@include media-breakpoint-down(lg) {
		display: flex;
		justify-content: center;
		.nav-link svg {
			height: 35px;
		}
	}
}

.credit-card-form {

	.input-field {
		font-size: 1.05rem;
		line-height: 1.5rem;
		display: block;
		box-sizing: border-box;
		width: 100%;
		border-radius: .25rem;
		color: $prestige-blue;
		border: 1px solid $border-color;
		//margin: 0 0 1rem;
		padding: 1rem 1.25rem;
		transition: border-color .3s ease;

		&:focus {
			outline: 0;
			border-color: $french-sky-blue;
		}
	}

	.cardholder-field .input-field {
		height: 3.45rem;
		text-transform: uppercase;
		//border: 1px solid $border-color;
	}

	.card-element {
		background: #fff;
		border: 1px solid $border-color;
		border-radius: 4px;
		padding: 1rem 1.25rem;
		max-height: 3.45rem;
		overflow: hidden;

		&.focus {
			border-color: $french-sky-blue;
		}

		&.invalid {
			border-color: $watermelon;
		}
	}

	.card-brand-icon {
		position: absolute;
		top: 45px;
		right: 15px;
		height: 24px;
		fill: #aab6c5;
	}

	.privacy-notice {
		padding: 1.3rem 0;
		font-size: 0.75rem;
		color: #8e9eb3;
		display: flex;
		align-items: center;
		line-height: .95rem;

		.privacy-notice-icon {
			width: 1.8rem;
			height: 1.8rem;
			background: #f4f7fb;
			color: #7a808c;
			margin-right: 0.75rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.15rem;
			border-radius: 50%;
		}

		.privacy-notice-text {
			display: inline-block;
			max-width: 26rem;
		}
	}

	.powered-by {
		svg {
			height: 24px;
			fill: #8e9eb3;
		}
	}

	.pay-btn {
		background: $wild-caribbean-green;
		color: #fff;
		padding: 0.675rem 0.75rem;
		font-size: .95rem;
		font-weight: 500;

		&:hover {
			background: $dark-mountain-meadow;
		}
	}

	.error-msg {
		display: none;
		color: $bruschetta-tomato;
		font-size: 0.85rem;
		margin-top: 0.25rem;

		&.is-visible {
			display: block;
		}
	}
}

.paypal-form {
	.pay-btn {
		background: #ffc439;
		color: #fff;
		padding: 0.675rem 0.75rem;
		font-size: .95rem;
		font-weight: 500;

		svg {
			height: 15px;
			padding: 0 0 0 .35rem;
		}

		&:hover {
			background: #ffb932;
		}
	}

	.privacy-notice {
		padding: 1.3rem 0;
		font-size: 0.75rem;
		color: #8e9eb3;
		display: flex;
		align-items: center;
		line-height: .95rem;

		.privacy-notice-icon {
			width: 1.8rem;
			height: 1.8rem;
			background: #f4f7fb;
			color: #7a808c;
			margin-right: 0.75rem;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.15rem;
			border-radius: 50%;
		}

		.privacy-notice-text {
			display: inline-block;
			max-width: 26rem;
		}
	}

	.powered-by {
		//padding: 1.3rem 0;

		svg {
			height: 24px;
			fill: #8e9eb3;
		}
	}
}