@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_utilities.scss';

$utilities: map-merge(
		$utilities,
		(
			'font-weight':
			map-merge(
					map-get($utilities, 'font-weight'),
					(
						values:
						map-merge(
								map-get(map-get($utilities, 'font-weight'), 'values'),
								(
									xthin: 100,
									thin: 200,
									medium: 500,
									semibold: 600
								)
						)
					)
			),
			'rounded':
			map-merge(
					map-get($utilities, 'rounded'),
					(
						values:
						map-merge(
								map-get(map-get($utilities, 'rounded'), 'values'),
								(
									4: $border-radius-xl,
									5: $border-radius-xxl
								)
						)
					)
			),
			'background-color':
			map-merge(
					map-get($utilities, 'background-color'),
					(
						values:
						map-merge(
								map-get(map-get($utilities, 'background-color'), 'values'),
								(
									"light-green": $lime-soap,
									"light-salmon": $light-salmon,
								)
						)
					)
			),
		)
);
/*
$utilities: map-merge(
		$utilities,
		(
			// 'cursor': (
			//   property: cursor,
			//   class: mega-cursor,
			//   responsive: true,
			//   values: auto pointer grab
			// ),
			"graviton-margin-top": (
				responsive: true,
				property: margin-top,
				class: gmt,
				values: (
					20: toREM(20),
					30: toREM(30)
				)
			),
			"graviton-margin-bottom": (
				responsive: true,
				property: margin-bottom,
				class: gmb,
				values: (
					5: toREM(5),
					10: toREM(10),
					20: toREM(20),
				)
			),
			"graviton-margin-y": (
				responsive: true,
				property: margin-top margin-bottom,
				class: gmy,
				values: (
					10: toREM(10),
					20: toREM(20),
					//30: toREM(30),
				)
			),
			"graviton-margin-right": (
				responsive: true,
				property: margin-right,
				class: gmr,
				values: (
					10: toREM(10),
					//5: toREM(5),
				)
			),
			"graviton-padding": (
				responsive: true,
				property: padding-top padding-bottom padding-right padding-left,
				class: gp,
				values: (
					20: toREM(20),
				)
			),
			"graviton-padding-y": (
				responsive: true,
				property: padding-top padding-bottom,
				class: gpy,
				values: (
					15: toREM(15),
				)
			),
			"graviton-padding-right": (
				responsive: true,
				property: padding-right,
				class: gpr,
				values: (
					20: toREM(20),
					30: toREM(30),
				)
			),
			"graviton-padding-left": (
				responsive: true,
				property: padding-left,
				class: gpl,
				values: (
					30: toREM(30),
				)
			),
			"graviton-width": (
				responsive: true,
				property: width,
				class: gw,
				values: (
					400: toREM(400),
				)
			),
			'font-weight':
			map-merge(
					map-get($utilities, 'font-weight'),
					(
						values:
						map-merge(
								map-get(map-get($utilities, 'font-weight'), 'values'),
								(
									xthin: 100,
									thin: 200,
									medium: 500,
									semibold: 600
								)
						)
					)
			)
		)
);
*/
