.card {
  //box-shadow: 0 12px 15px rgba(140, 150, 160,.1);
  .card-title {
    margin-bottom: 0;
  }
  &.card-bs {
    box-shadow: 0 5px 15px rgba(149, 161, 172, 0.1);
  }
}

.kaa-card-list {
  .card-item {
    border-bottom: 1px solid #e1e5f0;

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(odd) {
      background: #fefefe;
    }

    &:hover {
      background: #fdfdfe;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      padding: .8rem;
    }

    .card-item-details {
      flex-grow: 1;
      font-size: .75rem;
      color: $card-item-color;
      @include media-breakpoint-up(md) {
        width: calc(100% - 50px);
      }
      @include media-breakpoint-down(md) {
        padding: .8rem 1rem;
      }

      a {
        text-decoration: none;
      }
    }

    .card-item-selector {
      width: 30px;
      padding-right: 10px;
    }



    .card-item-image {
      width: 70px;
      margin-right: 20px;
      position: relative;
      //margin: 0 0 .85rem 0;
      border-radius: .25rem;
      overflow: hidden;
      box-shadow:.125vmin 0.125vmin 0.25vmin rgba(0, 0, 0, 0.1);

      .img-overlay {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: -0.125vmin -0.25vmin 0.5vmin rgba(0, 0, 0, 0.1) inset, -0.125vmin -0.125vmin 0.25vmin rgba(0, 0, 0, 0.2) inset;
          background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.1)), linear-gradient(to right, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0) 0.25vmin, rgba(0, 0, 0, 0.1) .6vmin, rgba(0, 0, 0, 0) 1vmin);
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .book-link {
        position: relative;
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        max-width: 100%;
        //max-height: 16rem;

        transform: scale(1);
        transition: all 0.3s ease-in-out;

        &:hover {
          //box-shadow: 0 32px 40px -12px rgba(38, 38, 38, .15);
          transform: scale(1.07);
        }

      }

    }

    //.card-item-image {
    //  width: 70px;
    //  padding-right: 20px;
    //
    //  img {
    //    max-width: 100%;
    //    border-radius: 4px;
    //    box-shadow: 0 3px 3px -2px rgba(39, 44, 51, 0.1), 0 3px 4px 0 rgba(39, 44, 51, 0.04), 0 1px 8px 0 rgba(39, 44, 51, 0.02);
    //    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    //    will-change: box-shadow;
    //  }
    //}

    .card-item-action {
      width: 50px;

      .more-action {
        text-decoration: none;
        font-size: 1.2rem;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        a {
          display: block;
          background: #f8f9fd;
          padding: .55rem .55rem .15rem;
          font-size: 1.2rem;
        }
      }

      .dropdown-menu {
        min-width: max-content;
        @include media-breakpoint-down(sm) {
          left: 50% !important;
          right: auto !important;
          text-align: center !important;
          transform: translate(-50%, 0) !important;
          margin-top: 39px;
          &:after, &:before {
            left: 50% !important;
            right: auto !important;
            text-align: center !important;
            transform: translate(-50%, 0) !important;
          }
        }
      }
    }

    //&.attr-item {
    //  .card-item-details {
    //    font-size: .85rem;
    //  }
    //}
  }

  .card-items-show-btn {
    display: block;
    padding: 1rem 0;
    color: $secondary;
    text-align: center;
    text-decoration: none;
    width: 100%;
    border: none;
    background: transparent;
    font-size: .85rem;
    text-transform: uppercase;
    font-weight: 500;
    outline: none;

    &:hover {
      color: $primary;
      background: #fdfdfe;
    }
  }
}