.me-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.ms-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.me-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.ms-n2, .mx-n2 {
  margin-left: -.5rem !important;
}